/* NOTE: FORMS*/
.form-control {
	background-clip: padding-box;
	background-color: #fff;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	color: #343a40;
	display: block;
	font-size: 1rem;
	font-weight: 400;
	/*height: calc(1.5em + 0.75rem + 2px);*/
	line-height: 1.5;
	padding: 0.375rem 0.75rem;
	transition:
		border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
	width: 100%;
}

.form-control:focus {
	/*color: #495057;*/
	/*background-color: #fff;*/
	border-color: #80bdff;
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

label {
	display: inline-block;
	margin-bottom: 0.5rem;
}

.form-group {
	margin-bottom: 1rem;
}

.form-control:disabled,
.form-control[readonly] {
	background-color: rgb(245, 247, 251) !important;
	opacity: 1;
}

/* NOTE: image input*/
input::file-selector-button {
	height: calc(1.5em + 0.75rem);
	padding: 0.375rem 0.75rem;
	margin: 0;
	margin-right: 1rem;
	border: 0;
	color: #212529;
	background: #e9ecef;
	/*https:/*developer.mozilla.org/en-US/docs/Web/CSS/::file-selector-button *fallback optinos*/
}
input[type='file']:hover,
input[type='file']::file-selector-button:hover {
	cursor: pointer;
}

/* NOTE: mui time and date input*/
.MuiStack-root {
	width: 100%;
	padding: 0 !important;
	overflow: visible !important; /* NOTE: overflow visible to see the box shadow in a Date and time input*/
}

/*NOTE: place date input ok button centered*/
.MuiPickersLayout-actionBar {
	margin: auto !important;
}
