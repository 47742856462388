body {
	font-family:
		'Lexend',
		-apple-system,
		BlinkMacSystemFont,
		'Segoe UI',
		Roboto,
		'Helvetica Neue',
		Arial,
		sans-serif,
		'Apple Color Emoji',
		'Segoe UI Emoji',
		'Segoe UI Symbol',
		'Noto Color Emoji';
	color: #5c646c;
	background: #f1f5f9;
	font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6,
.text-dark {
	color: #343a40;
}

/* NOTE: ANIMATIONS*/
.rotate {
	animation: rotate 4s linear infinite;
}

@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.skeleton {
	background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
	background: linear-gradient(90deg, #d0dae6 25%, #e0e7f1 50%, #d0dae6 75%);
	background-size: 200% 100%;
	animation: loading 3s infinite;
}
@keyframes loading {
	0% {
		background-position: 200% 0;
	}
	100% {
		background-position: -200% 0;
	}
}
