/* NOTE: CALENDAR*/
.react-calendar button {
	border: 0;
	outline: none;
}
.react-calendar__month-view__weekdays {
	text-align: center;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
	padding: 0.5em;
}
abbr[data-original-title],
abbr[title] {
	text-decoration: none !important;
	cursor: default !important;
}
.react-calendar__month-view__days__day--weekend {
	color: gray;
}
.react-calendar__month-view__days__day--neighboringMonth {
	color: lightgray;
}
.react-calendar__tile {
	max-width: 100%;
	padding: 10px 6.6667px;
	background: none;
	text-align: center;
	line-height: 16px;
	border-radius: 4px;
}
/* NOTE: today styling*/
.staff-calendar .react-calendar__tile--now,
.staff-calendar .react-calendar__tile--now:enabled:hover,
.staff-calendar .react-calendar__tile--now:enabled:focus {
	background: #f1f5f9;
	color: #343a40;
	font-weight: 500;
}
/* NOTE: selected styling*/
.react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
	background: linear-gradient(315deg, #ffe4d9, #ffe0b3) !important;
	color: #343a40 !important;
}
