button:disabled {
	cursor: not-allowed;
	opacity: 0.5;
	pointer-events: none;
}

a:hover {
	text-decoration: none;
	color: inherit;
}
a {
	cursor: pointer;
	color: inherit;
}

/* TODO: only used on navbar - remove and use tailwind instead - create a NavbarLink component*/
.nav-link {
	font-size: 18px;
	font-weight: 500;
	border-radius: 4px;
	background-color: none;
	border: 1.5px solid transparent;
	transition: background-color 0.4s ease-in-out;
	transition: outline-color 0.2s ease-in;
	font-weight: 600;
}
