/* NOTE: NOTIFICATION*/
.rnc__notification-container--top-right {
	position: fixed !important;
}
.rpv-default-layout__toolbar {
	background-color: white !important;
}
.rpv-default-layout__sidebar-headers {
	display: none;
}
.rpv-toolbar__left {
	display: none !important;
}
.rpv-open__input-wrapper {
	display: none;
}
