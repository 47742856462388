@tailwind base;
@tailwind components;
@tailwind utilities;
/* NOTE: default installed components styling*/
@import '@szhsin/react-menu/dist/index.css';
@import '@szhsin/react-menu/dist/transitions/slide.css';
@import url('https:/*fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* NOTE: custom styling for packages*/
@import './base.css';
@import './buttonsAndLinks.css';
@import './forms.css';
@import './calendar.css'; /* TODO: These are specific to the calendar component and should be a css module in the component directory */
@import './notifications.css'; /* TODO: These are specific to the notification banner or toasts and should be a css module in the component directory */
/* TODO: these are specific to the text editor component, build as a css module instead of having it here*/
.undoButton,
.redoButton {
	border: none !important ;
	background-color: transparent !important ;
}
