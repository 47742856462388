/* NOTE: SWIPER*/

.twd-carousel .swiper {
	overflow: visible !important;
}
.twd-carousel .swiper-slide {
	transform: none !important;
}
.twd-carousel .swiper-pagination {
	margin-top: 0.8rem;
	position: initial !important;
}

.twd-carousel .swiper-pagination-bullet {
	width: 0.6rem !important;
	height: 0.6rem !important;
	background: #a8b5c5 !important;
	opacity: 0.5 !important;
}

.twd-carousel .swiper-pagination-bullet-active {
	opacity: 1 !important;
}
.twd-carousel .swiper-button-prev,
.twd-carousel .swiper-button-next {
	color: white;
	top: 50%;
	width: 2.7rem;
	height: 2.7rem;
	border-radius: 50%;
	background-color: #d0dae680;
	box-shadow:
		rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
		rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.twd-carousel .swiper-button-prev:after,
.twd-carousel .swiper-button-next:after {
	font-size: 1.2rem;
	font-weight: bolder;
}
.twd-carousel .swiper-button-disabled {
	display: none;
}
.twd-carousel .swiper-button-prev {
	left: 0;
}
.twd-carousel .swiper-button-next {
	right: 0;
}

.twd-carousel .swiper-button-prev:after {
	margin-right: 2px;
}

.twd-carousel .swiper-button-next:after {
	margin-left: 2px;
}
